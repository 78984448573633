<template>
    <v-flex fill-height>
        <v-layout justify-space-between pa-2 row>
            <v-flex v-t="'sa.support.actions.change-geds-of-vendors.title'" headline shrink />
        </v-layout>
		<v-layout fill-height pa-4>
            <v-flex>
                <v-layout column justify-center>
                    <v-flex my-3 shrink>
                        <v-layout column>
                            <v-flex v-t="'sa.support.actions.change-geds-of-vendors.accounting-firm'" class="title" mb-3 />
                                <v-autocomplete
                                    v-model="selectedAccountingFirmId"
                                    color="primary"
                                    :items="accountingFirms"
                                    :label="$t('accounting_firm')"
                                    :loading="loadingAccountingFirmsList"
                                />
                                <v-flex v-t="'sa.support.actions.change-geds-of-vendors.catalog-tree-structure'" class="title" mb-3 />
                                <v-autocomplete
                                    v-model="selectedCatalogTreeStructureId"
                                    color="primary"
                                    :items="catalogTreeStructures"
                                    :label="$t('sa.ged_structures')"
                                    :loading="loadingCatalogTreeStructuresList"
                                />
                            </v-layout>
                    </v-flex>
                    <v-flex my-3 shrink>
                        <WButton :disabled="!selectedAccountingFirmId || !selectedCatalogTreeStructureId" @click="execute">{{ $t('actions.launch') }}</WButton>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-flex>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
export default {
    name: 'VendorsGEDChange',
    mixins: [SuperAdminModuleGuard],
    data: function () {
		return {
			accountingFirms: new Array(),
            catalogTreeStructures: new Array(),
            selectedCatalogTreeStructureId: null,
            selectedAccountingFirmId: null,
            loadingAccountingFirmsList: false,
            loadingCatalogTreeStructuresList: false,
        }
    },
    created: function () {
		this.getAccountingFirms()
        this.getCatalogTreeStructures()
	},
    methods: {
        getAccountingFirms: function () {
			this.loadingAccountingFirmsList = true
			this.service
				.getAccountingFirms()
				.then(accountingFirms => {
					return accountingFirms.map(accountingFirm => {
						return {
							text: accountingFirm.name,
							value: accountingFirm.id
						}
					})
				})
				.then(accountingFirms => {
					this.accountingFirms = accountingFirms
				})
				.finally(() => {
					this.loadingAccountingFirmsList = false
				})
		},
        getCatalogTreeStructures: function () {
			this.loadingCatalogTreeStructuresList = true
			this.service
				.getCatalogTreeStructures()
				.then(catalogTreeStructures => {
					return catalogTreeStructures.map(catalogTreeStructure => {
						return {
							text: catalogTreeStructure.name,
							value: catalogTreeStructure.id
						}
					})
				})
				.then(catalogTreeStructures => {
					this.catalogTreeStructures = catalogTreeStructures
				})
				.finally(() => {
					this.loadingCatalogTreeStructuresList = false
				})
		},
        execute: function () {
            return this.service.executeCommand({ 
                    command: "accounting-firms:vendors:catalog-tree-structure:update", 
                    params: {
                        'accounting-firm-id': this.selectedAccountingFirmId, 
                        'catalog-tree-structure-id': this.selectedCatalogTreeStructureId, 
                    }
                })
            .then(() => {
                this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.support.success'))
            })
        }
    }
}
</script>